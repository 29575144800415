import React, { useEffect, useState } from 'react'

import { Grid, Typography, Avatar, Link } from '@mui/material'
import DashboardCard from './DashboardCard'
import { useTranslation } from '@contexts/translation'
import numberWithCommas from '@utils/numberWithCommas'
import { useAuth } from '@contexts/auth'
import getTotal from '@utils/getTotal'
import { useTheme } from '@mui/material/styles'
import { Stack } from '@mui/system'
import CommonModal from '@components/common/Modal/CommonModal'
import DetailModal from '@components/Invoicing/Tenants/DetailModal'
import { ArrowForward } from '@mui/icons-material'
import routes from '@config/routes'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

import { MetricSkeleton } from '@components/common/Skeletons'

export default function Tenants(props) {
  const { dictionary } = useTranslation()
  const { data } = props
  const { orgSettings } = useAuth()
  const theme = useTheme()

  const [tenants, setTenants] = useState([])
  const [total, setTotal] = useState(0)
  const [detailModal, setDetailModal] = useState({
    open: false,
    id: null
  })

  useEffect(() => {
    if (data) {
      setTenants(
        data.results
          ?.filter(tenant => tenant.balance > 0)
          .sort((a, b) => b.balance - a.balance)
      )
      setTotal(getTotal(data.results, 'balance'))
    }
  }, [data])

  if (!data)
    return (
      <DashboardCard>
        <MetricSkeleton />
      </DashboardCard>
    )

  const tenantColors = [
    { color: '#f8931a', bgcolor: '#fef0dd' },
    { color: '#627fea', bgcolor: '#e9ecfc' },
    { color: '#7762ea', bgcolor: '#eceaff' },
    { color: '#0033ae', bgcolor: '#d9e1f4' },
    { color: '#222222', bgcolor: '#dedede' },
    { color: '#68a99b', bgcolor: '#e2f4ef' }
  ]

  return (
    <>
      <DashboardCard>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Stack>
              <Typography
                variant="h6"
                fontSize={18}
                data-testid="Typography-6d0952d1-9a3c-4ed8-8608-9486243daf67"
              >
                {dictionary.tenant_balance}
              </Typography>
              <Stack
                paddingY={4}
                paddingRight={1}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography
                    variant="h6"
                    fontSize={20}
                    color="secondary"
                    data-testid="Typography-f9fe961c-ef9c-4f91-8ca5-e7c5825eed4e"
                  >
                    {numberWithCommas(total, 2)}
                    <span
                      style={{
                        fontSize: '20px',
                        color: '#7966eb'
                      }}
                      data-testid="span-0fd3a027-a128-4bef-82d7-3116b42b0562"
                    >
                      {orgSettings?.currency_symbol || '€'}
                    </span>
                  </Typography>

                  {/* FOR LATER : add rate of change on indicator */}
                  {/* <Stack direction="row" gap={0.5} alignItems="center">
                      <NorthEast
                        fontSize="8"
                        color="success"
                        sx={{
                          backgroundColor: theme.palette.success.light,
                          borderRadius: '5px',
                          padding: 0.2
                        }}
                      />
                      <Typography
                        fontWeight={600}
                        fontSize={12}
                        color={theme.palette.success.main}
                      >
                        1.6%
                      </Typography>
                     </Stack> */}
                </Stack>
              </Stack>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingRight: 1
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={600}
                  data-testid="Typography-e88a0e0e-4f67-4be4-b5a0-9aff769a2ce2"
                >
                  {dictionary.tenants}
                </Typography>
                <Link
                  href={routes.contacts.tenants}
                  sx={{ textDecoration: 'none' }}
                >
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    p={1}
                    sx={{
                      borderRadius: '8px',
                      transition: 'all 0.3s ease-out',
                      '&:hover': { backgroundColor: 'primary.light' }
                    }}
                  >
                    <Typography
                      fontWeight={500}
                      fontSize={12}
                      color={theme.palette.primary.main}
                      data-testid="Typography-68a83751-107b-4242-bf0b-e0106d487638"
                    >
                      {dictionary.see_all_tenants}
                    </Typography>
                    <ArrowForward
                      fontSize="8"
                      sx={{
                        stroke: theme.palette.primary.main,
                        strokeWidth: 1.5
                      }}
                    />
                  </Stack>
                </Link>
              </Grid>
              <Grid container spacing={2} paddingY={1} marginTop={0}>
                <>
                  {tenants?.length === 0 && (
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      padding={2}
                      sx={{
                        border: '1px solid #e0e0e0',
                        borderRadius: '10px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: 15,
                        textAlign: 'center'
                      }}
                    >
                      <CheckCircleOutlineIcon
                        sx={{ marginRight: 1 }} // Adds some space between the icon and the text
                      />
                      <Typography
                        fontSize={12}
                        fontWeight={700}
                        data-testid="Typography-2d14b5be-d115-41b1-ad63-d2f63ea7506e"
                      >
                        {dictionary.paid}
                      </Typography>
                    </Stack>
                  )}
                  {tenants?.slice(0, 6).map((tenant, index) => (
                    <Grid key={index} item xs={12} sm={6}>
                      <Stack
                        padding={1.6}
                        direction="row"
                        gap={2}
                        sx={{
                          border: '1px solid #e0e0e0',
                          borderRadius: '10px',
                          cursor: 'pointer'
                        }}
                        onClick={() =>
                          setDetailModal({
                            open: true,
                            id: tenant.id,
                            header: tenant.display
                          })
                        }
                      >
                        <Avatar
                          sx={{
                            borderRadius: '10px',
                            color: tenantColors[index].color,
                            backgroundColor: tenantColors[index].bgcolor
                          }}
                        >
                          {tenant.display.substring(0, 2)}
                        </Avatar>
                        <Stack width="100%" gap={1}>
                          <Typography
                            fontSize={12}
                            fontWeight={700}
                            data-testid="Typography-2d14b5be-d115-41b1-ad63-d2f63ea7506e"
                          >
                            {tenant?.display}
                          </Typography>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={1}
                          >
                            {/* <Typography fontSize={12}>Lot name</Typography> */}
                            <Typography
                              fontSize={12}
                              data-testid="Typography-bc24bc2a-a3aa-4978-9630-0d7f7ba70f86"
                            >
                              {numberWithCommas(
                                tenant.balance,
                                2,
                                orgSettings?.currency_symbol || '€'
                              )}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Grid>
                  ))}
                </>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </DashboardCard>
      <CommonModal
        open={detailModal.open}
        title={detailModal.header}
        onClose={() => setDetailModal({ open: false, id: null })}
        maxWidth={'md'}
        noPadding
        isAlignTop
      >
        <DetailModal
          /* operationId={queries.operations} */
          handleClose={() => setDetailModal({ open: false, id: null })}
          tenantId={detailModal.id}
          currency={orgSettings?.currency_symbol}
        />
      </CommonModal>
    </>
  )
}
