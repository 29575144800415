import React from 'react'
import { Grid, Typography, IconButton, Link } from '@mui/material'
import Metric from '@components/Indicators/Metric'
import { useTranslation } from '@contexts/translation'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const MetricsDisplay = ({ indicatorData, isLoading }) => {
  const { dictionary } = useTranslation()

  const renderMetricsSection = (title, data, reportLink) => (
    <>
      <Grid item xs={12} container alignItems="center">
        <Typography variant="h6">{title}</Typography>
        {reportLink && (
          <Link href={reportLink} rel="noopener">
            <IconButton size="small" edge="end" aria-label="view report">
              <OpenInNewIcon fontSize="small" />
            </IconButton>
          </Link>
        )}
      </Grid>
      {Object.keys(data).map((key, index) => (
        <Grid item xs={12} md={4} key={index}>
          <Metric indicator={data[key]} />
        </Grid>
      ))}
    </>
  )

  return (
    <>
      {isLoading && (
        <>
          {[...Array(4)].map((_, index) => (
            <Grid item sm={3} key={index}>
              <Metric />
            </Grid>
          ))}
        </>
      )}

      {indicatorData?.data && (
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={12} container spacing={3}>
            {renderMetricsSection(
              dictionary.operational,
              indicatorData.data.operational_metrics,
              '/projects/summary'
            )}
            {renderMetricsSection(
              dictionary.tenants,
              indicatorData.data.tenant_management,
              '/invoicing/tenants'
            )}
            {renderMetricsSection(
              dictionary.invoicing_and_payments,
              indicatorData.data.billing_and_payments,
              '/invoicing/invoices'
            )}
            {renderMetricsSection(
              dictionary.financials,
              indicatorData.data.income
            )}
            {renderMetricsSection(
              dictionary.expenses,
              indicatorData.data.expenses,
              '/costs/expenses'
            )}
            {renderMetricsSection(
              dictionary.financial_ratios,
              indicatorData.data.ratios_and_returns
            )}
            {renderMetricsSection(
              dictionary.valuation,
              indicatorData.data.valuations_and_debt
            )}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default MetricsDisplay
