import { useState, useEffect } from 'react'
import { Grid, CircularProgress } from '@mui/material'

import Tabs from './Tabs'

import { useFetchData } from '@api/fetchData'
import API_URL from '@config/services'
import PropTypes from 'prop-types'

const DetailModal = ({
  operationId = null,
  handleClose,
  tenantId,
  fetchURL,
  currency
}) => {
  const [data, setFoldersData] = useState([])

  const tenantURL = tenantId ? API_URL.TENANTS.GET_TENANT_BY_ID(tenantId) : null
  const { data: tenantData, isLoading } = useFetchData(tenantURL)

  const paymentURL = API_URL.TENANTS.GET_TENANT_PAYMENTS(tenantId)
  const { data: paymentData } = useFetchData(tenantId ? paymentURL : null)

  const filesURL = API_URL.FILES.GET_FILES_LIST(operationId)

  const foldersURL = API_URL.FILES.GET_FILES_FOLDER_LIST(operationId)
  const { data: foldersList } = useFetchData(foldersURL)
  const { data: filesList } = useFetchData(filesURL)

  useEffect(() => {
    if (foldersList && filesList) {
      setFoldersData([
        ...foldersList.results,
        ...filesList.results.map(item => ({
          isFile: true,
          parent: item.folder,
          ...item
        }))
      ])
    }
  }, [foldersList, filesList])

  return (
    <>
      {isLoading && (
        <Grid item xs={12} align="center">
          <CircularProgress color="secondary" sx={{ m: 10 }} />
        </Grid>
      )}
      {!isLoading && tenantData && (
        <Tabs
          tenantData={tenantData}
          paymentData={paymentData?.results}
          filesData={data}
          handleClose={handleClose}
          fetchURL={fetchURL}
          fetchTenantURL={tenantURL}
          tenantId={tenantId}
          operationId={operationId}
          currency={currency}
        />
      )}
    </>
  )
}

export default DetailModal

DetailModal.propTypes = {
  operationId: PropTypes.number,
  handleClose: PropTypes.func,
  tenantId: PropTypes.number,
  fetchURL: PropTypes.string,
  currency: PropTypes.string
}
