export default function abbreviateNumber(number) {
  const numberAsString = String(number || 0)
  const digits = numberAsString.length

  if (digits === 5) {
    return `${numberAsString.substring(0, 2)}k`
  } else if (digits === 6) {
    return `${numberAsString.substring(0, 3)}k`
  } else if (digits >= 7) {
    const digitsToShowArr = numberAsString.substring(0, digits - 5).split('')
    digitsToShowArr.splice(-1, 0, '.')

    const result = `${digitsToShowArr.join('')}M`

    return result
  } else {
    return numberAsString
  }
}
