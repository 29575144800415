import React from 'react'
import { Grid, Typography } from '@mui/material'
import { format } from '@utils/date-fns_functions'
import { useTranslation } from '@contexts/translation'
import { useAuth } from '@contexts/auth'
import capitalizeFirstLetter from '@utils/capitalize'
import { Stack } from '@mui/system'
import { CalendarToday } from '@mui/icons-material'

export default function Welcome() {
  const { dictionary, lang } = useTranslation()
  const { user } = useAuth()
  return (
    <Grid
      container
      paddingX={3}
      paddingTop={3}
      sx={{
        height: '100%',
        backgroundColor: theme => theme.palette.elements.light,
        borderRadius: theme => theme.spacing(1)
      }}
    >
      <Grid item xs={12} container spacing={1}>
        <Grid
          container
          style={{
            margin: 'auto 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Stack>
            <Typography
              variant="h5"
              fontWeight={700}
              data-testid="Typography-35e1ee47-b368-4a51-9ef5-e0c46d0ff496"
            >
              {`${dictionary.welcome_back} ${user.first_name}`}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ mt: 1 }}
              data-testid="Typography-240de733-6db3-4f7e-848d-f1880d3a3fbf"
            >
              {dictionary.welcome_desc}
            </Typography>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap={1.5}>
            <CalendarToday sx={{ height: '18px', color: 'text.light' }} />
            <Typography
              variant="subtitle2"
              data-testid="Typography-e6972ad1-2e57-4b12-8a8e-b584c3e963f4"
            >
              {capitalizeFirstLetter(format(new Date(), 'eeee', lang))}
              {', '}
              {capitalizeFirstLetter(format(new Date(), 'MMM', lang))}{' '}
              {format(new Date(), 'dd', lang)}
              {', '}
              {format(new Date(), 'yyyy', lang)}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}
