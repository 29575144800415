import { useEffect, useState } from 'react'
import { Box, Popover } from '@mui/material'

const CustomPopover = ({
  buttonLabel,
  anchorOrigin = {
    vertical: 'center',
    horizontal: 'left'
  },
  transformOrigin = {
    vertical: 'center',
    horizontal: 'right'
  },
  closed = false,
  setClosed,
  children
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  useEffect(() => {
    if (closed) {
      setAnchorEl(null)
      setClosed && setClosed(false)
    }
  }, [closed])

  const handleClick = event => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = event => {
    event?.stopPropagation()
    event?.preventDefault()
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Box>
      <Box onClick={handleClick}>{buttonLabel}</Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {children}
      </Popover>
    </Box>
  )
}

export default CustomPopover
