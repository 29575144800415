import React from 'react'

import { Grid } from '@mui/material'

export default function DashboardCard({ children }) {
  return (
    <Grid
      container
      padding={2}
      sx={{
        height: '100%',
        backgroundColor: theme => theme.palette.primary.contrastText,
        borderRadius: theme => theme.spacing(3)
      }}
    >
      <Grid
        item
        xs={12}
        container
        spacing={1}
        sx={{ paddingLeft: 2, paddingTop: 2 }}
      >
        {children}
      </Grid>
    </Grid>
  )
}
