import React from 'react'

import { Grid, Typography } from '@mui/material'
import {
  Area,
  Line,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  AreaChart
} from 'recharts'
import DashboardCard from './DashboardCard'
import { useTranslation } from '@contexts/translation'
import numberWithCommas from '@utils/numberWithCommas'
import { useTheme } from '@mui/material/styles'
import { Stack } from '@mui/system'
import { MetricSkeleton } from '@components/common/Skeletons'
import Link from 'next/link'
import routes from '@config/routes'

export default function Unpaid(props) {
  const { data, currency } = props
  const { t } = useTranslation()
  const theme = useTheme()

  if (!data)
    return (
      <DashboardCard>
        <MetricSkeleton />
      </DashboardCard>
    )

  const rechartData = Object.keys(data?.time_series).map(key => {
    const monthData = data?.time_series[key]
    return {
      name: monthData.month,
      value: monthData.unpaid_revenue
    }
  })

  return (
    <DashboardCard>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            fontSize={18}
            data-testid="Typography-ff356072-e656-485f-8d8c-ee3a5d918efa"
          >
            {t('dashboard_unpaid_title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} height="100%">
            <Grid item xs={4}>
              <Stack
                gap={3}
                justifyContent="end"
                alignItems="start"
                height="100%"
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <Link href={routes.invoicing.invoices} passHref>
                    <Typography
                      variant="h6"
                      fontSize={20}
                      color="secondary"
                      data-testid="Typography-89f1ca62-9ffc-4f2f-9c68-6587749b3e23"
                    >
                      {data?.unpaid_revenue > 1000
                        ? numberWithCommas(data?.unpaid_revenue / 1000, 1) + 'k'
                        : numberWithCommas(data?.unpaid_revenue)}
                      <span
                        style={{
                          fontSize: '16px',
                          color: '#7966eb'
                        }}
                      >
                        {currency || '€'}
                      </span>
                    </Typography>
                  </Link>
                </Stack>

                <Stack gap={0.5}>
                  <Typography
                    color={theme.typography.subtitle1.color}
                    fontSize={15}
                  ></Typography>
                </Stack>
              </Stack>
            </Grid>
            {rechartData && (
              <Grid item xs={8}>
                <ResponsiveContainer width="100%" height={150}>
                  <AreaChart
                    data={rechartData}
                    margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
                  >
                    <XAxis dataKey="month" hide />
                    <defs>
                      <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#8884d8"
                          stopOpacity={0.2}
                        />
                        <stop
                          offset="95%"
                          stopColor="#8884d8"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>
                    <Area
                      type="monotone"
                      dataKey="value"
                      stroke="#8884d8"
                      fillOpacity={1}
                      fill="url('#colorUv')"
                    />

                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#8884d8"
                      strokeWidth={2}
                      dot={false}
                    />

                    <Tooltip
                      cursor={{ fill: 'transparent' }}
                      formatter={v => numberWithCommas(v || 0, 2)}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </DashboardCard>
  )
}
