import { Modal, Backdrop, Grow, Box, Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { ErrorOutline } from '@mui/icons-material'
import { useTranslation } from '@contexts/translation'
import useStyles from './styles'

const DeleteModal = ({
  open,
  onClose,
  onConfirm,
  description = '',
  loading = false,
  type = 'delete'
}) => {
  const classes = useStyles()
  const { dictionary } = useTranslation()

  return (
    <Modal
      className={classes.modal}
      open={open || false}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Grow in={open}>
        <Box
          className={classes.paper}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <ErrorOutline color="warning" style={{ fontSize: '100px' }} />
          <p style={{ whiteSpace: 'pre-wrap' }}>{description}</p>
          <Box className="actions__block">
            <Button
              variant="contained"
              className="default-btn"
              onClick={onClose}
              data-testid="Button-96f33b22-1422-42da-b9ab-f07edb99781f"
            >
              {dictionary.cancel}
            </Button>
            <LoadingButton
              variant="contained"
              onClick={() => onConfirm()}
              loading={loading}
              color={type === 'delete' ? 'error' : 'primary'}
              data-testid="LoadingButton-d4488294-347d-42cb-9ab3-4b837401628f"
            >
              {dictionary.ok}
            </LoadingButton>
          </Box>
        </Box>
      </Grow>
    </Modal>
  )
}

export default DeleteModal
