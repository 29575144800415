import React, { useEffect } from 'react'

const YbugScript = ({ user }) => {
  useEffect(() => {
    const allowedDomains = [
      'ooti.co',
      'keysy.co',
      'griahi@albatros-associates.com',
      'maxriahi@gmail.com'
    ]
    //  && allowedDomains.some(domain => user.email.includes(domain))

    if (user) {
      // Define the ybug_settings global variable
      const settingsScript = document.createElement('script')
      settingsScript.type = 'text/javascript'
      settingsScript.innerHTML = `
        window.ybug_settings = {"id":"4tgbd0b2fgc901hrb9bb"};
      `
      document.body.appendChild(settingsScript)

      // Append the Ybug script
      const ybugScript = document.createElement('script')
      ybugScript.type = 'text/javascript'
      ybugScript.async = true
      ybugScript.src = 'https://widget.ybug.io/button/4tgbd0b2fgc901hrb9bb.js'

      document.body.appendChild(ybugScript)

      return () => {
        document.body.removeChild(settingsScript)
        document.body.removeChild(ybugScript)
      }
    }
  }, [user])

  return null
}

export default YbugScript
