import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from '@contexts/translation'
import useTableStyles from '@styles/table/table'

import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from '@mui/material'
import PlaceHolder from '@components/common/PlaceHolder'
import numberWithCommas from '@utils/numberWithCommas'
import FixedFooter from '@components/FixedFooter'
import getTotal from '@utils/getTotal'
import FiltersElement from '@components/common/Filter/FiltersElement'

export default function LeasesModal({
  isLeasePeriodsLoading,
  title,
  leasePeriodsLeaving,
  leasePeriodsStarting,
  leasePeriodsRenewed,
  leases,
  filterQueries,
  dispatchFilterQueries,
  operationId
}) {
  const { dictionary, t } = useTranslation()
  const classesTable = useTableStyles()
  const [tenants, setTenants] = useState([])

  useEffect(() => {
    if (title === dictionary.tenants_leaving) {
      setTenants(leasePeriodsLeaving)
    }
    if (title === dictionary.tenants_arriving) {
      setTenants(leasePeriodsStarting)
    }
    if (title === dictionary.contracts_renewing) {
      setTenants(leasePeriodsRenewed)
    }
  }, [title, leasePeriodsLeaving, leasePeriodsStarting, leasePeriodsRenewed])

  const getTotalRent = () => {
    const rentArray = tenants?.map(
      item => leases?.find(lease => lease?.id === item?.lease?.id)?.rent
    )
    const sum = rentArray?.reduce((a, b) => a + b, 0)
    return sum
  }

  const FooterObjData = {
    0: { name: 'Total', align: 'left' },
    6: { name: numberWithCommas(getTotalRent(), 2) },
    7: {
      name: numberWithCommas(
        getTotal(
          tenants?.map(item => item?.lease),
          'balance'
        ),
        2
      )
    }
  }

  return (
    <Grid container spacing={2}>
      {!operationId && (
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <FiltersElement
            defaultQueries={{}}
            handleQueries={dispatchFilterQueries}
            operation={filterQueries?.operation || ''}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TableContainer className={classesTable.container}>
          <Table>
            {tenants?.length > 0 && (
              <TableHead>
                <TableRow hover={false}>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.operation}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.name}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {dictionary.email}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {t('dashboard_rents_evolution_activity_type')}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {t('start_date')}
                  </TableCell>
                  <TableCell className={classesTable.headerCell}>
                    {t('end_date')}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {t('rent')}
                  </TableCell>
                  <TableCell className={classesTable.headerCell} align="right">
                    {dictionary.balance}
                  </TableCell>
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {isLeasePeriodsLoading && (
                <TableRow className={classesTable.row}>
                  <TableCell align="center" colSpan={'100%'}>
                    <CircularProgress color="secondary" />
                  </TableCell>
                </TableRow>
              )}

              {!isLeasePeriodsLoading && !tenants?.length > 0 && (
                <TableRow hover={false} className={classesTable.row}>
                  <TableCell align="center" colSpan={'100%'}>
                    <PlaceHolder
                      src="/Placeholders/Project_Settings_Clients.png"
                      width={'500px'}
                      height={'450px'}
                    />
                  </TableCell>
                </TableRow>
              )}

              {!isLeasePeriodsLoading &&
                tenants?.length > 0 &&
                tenants?.map(tenant => (
                  <TableRow
                    key={tenant?.lease?.id}
                    className={classesTable.row}
                  >
                    <TableCell>{tenant?.operation_display}</TableCell>
                    <TableCell>
                      {tenant?.lease?.tenant?.display
                        ? tenant?.lease?.tenant?.display
                        : '---'}
                    </TableCell>
                    <TableCell>
                      {tenant?.lease?.tenant?.email
                        ? tenant?.lease?.tenant?.email
                        : '---'}
                    </TableCell>
                    <TableCell>
                      {tenant?.lease?.tenant?.activity_type
                        ? tenant?.lease?.tenant?.activity_type
                        : '---'}
                    </TableCell>
                    <TableCell>
                      {tenant?.start_date ? tenant?.start_date : '---'}
                    </TableCell>
                    <TableCell>
                      {tenant?.end_date ? tenant?.end_date : '---'}
                    </TableCell>
                    <TableCell align="right">
                      {numberWithCommas(
                        leases?.find(lease => lease?.id === tenant?.lease?.id)
                          ?.rent,
                        2
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {numberWithCommas(tenant?.lease?.balance, 2)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
            {!isLeasePeriodsLoading && (
              <TableFooter className={classesTable.footer}>
                <FixedFooter data={FooterObjData} totalCol={8} />
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

LeasesModal.propTypes = {
  isLeasePeriodsLoading: PropTypes.bool,
  title: PropTypes.string,
  leasePeriodsLeaving: PropTypes.array,
  leasePeriodsStarting: PropTypes.array,
  leasePeriodsRenewed: PropTypes.array,
  leases: PropTypes.array,
  filterQueries: PropTypes.object,
  dispatchFilterQueries: PropTypes.func,
  operationId: PropTypes.number
}
