import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    padding: '17px',
    width: '478px',
    // boxShadow: theme.shadows[5],
    zIndex: '1',

    '& p': {
      margin: '20px 0',
      fontWeight: '300',
      fontSize: '19px',
      color: '#797979',
      textAlign: 'center'
    },

    '& .actions__block': {
      marginTop: '25px',

      '& > button': {
        margin: '0 5px',
        padding: '10px 32px',
        textTransform: 'initial',
        fontSize: '17px',

        '&.default-btn': {
          backgroundColor: '#D0D0D0'
        },

        '&.confirm-btn': {
          backgroundColor: '#aedef4'
        }
      }
    }
  }
}))

export default useStyles
