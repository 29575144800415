import { useEffect } from 'react'

const FreshworksWidget = ({ user }) => {
  useEffect(() => {
    const pageUrl = window.location.href
    const browserInfo = `${navigator.appName} ${navigator.appVersion}`
    const userAgent = navigator.userAgent

    window.fwSettings = {
      widget_id: 202000001431,
      locale: 'fr',
      // Add color customization
      open: {
        chat: {
          background_color: '#7662EA', // Replace with your desired color code
          text_color: '#000' // Replace with your desired text color code
        }
      }
    }
    ;(function () {
      if (typeof window.FreshworksWidget !== 'function') {
        var n = function () {
          n.q.push(arguments)
        }
        n.q = []
        window.FreshworksWidget = n
      }
    })()

    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://euc-widget.freshworks.com/widgets/202000001431.js'
    script.async = true
    script.defer = true
    script.onload = () => {
      const fillForm = () => {
        window.FreshworksWidget('identify', 'ticketForm', {
          email: user.email,
          name: `${user.first_name} ${user.last_name}`,
          description: `\n\n-------\nPage URL: ${pageUrl}\n\nBrowser Info: ${browserInfo}`
        })
      }

      // Fill the form initially when the widget is loaded
      fillForm()

      // Listen for the widget open event and fill the form again
      window.FreshworksWidget('on', 'widget:opened', fillForm)
    }
    document.body.appendChild(script)

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script)
    }
  }, [user])

  return null // This component does not render anything
}

export default FreshworksWidget
